<template>
  <div class="my-3 text-center">
    <template v-if="state != 'success'">
      <b-alert v-if="state == 'error'" variant="danger" class="text-left" show>
        <h4 class="alert-heading">{{ $t('_errors.general.title') }}:</h4>
        <p>{{ error.message }}</p>
        <b-collapse id="error-details" class="my-2">
          <b-card>
            <pre>{{ error.details }}</pre>
          </b-card>
        </b-collapse>
        <!-- <b-btn v-b-toggle.error-details>{{ $t('login.buttons.error-details') }}</b-btn> -->
      </b-alert>
      <p class="mb-3 lead">{{ $t('login.description') }}:</p>
      <b-row>
        <b-col cols="8" offset="2" lg="4" offset-lg="4">
          <b-form class="my-2 mx-auto justify-content-center" @submit.prevent>
            <b-form-input
              v-e2e:emailInput
              class="my-3"
              v-model="email"
              :placeholder="$t('e-mail')"
              name="email"
            />
            <b-form-input
              v-e2e:passwordInput
              class="my-3"
              v-model="password"
              :placeholder="$t('password')"
              name="password"
              type="password"
            />
            <b-button
              v-e2e:loginButton
              class="my-3 px-5"
              variant="primary"
              type="submit"
              @click="login"
            >
              {{ $t('login.buttons.login') }}
            </b-button>
            <br />
            <br />
            <p>
              <router-link
                class="text-decoration-none text-secondary"
                to="/request-password-reset"
              >
                {{ $t('forgotten-or-no-password-hint') }}
              </router-link>
            </p>
            <b-btn v-e2e:resetPasswordButton href="/request-password-reset">
              {{ $t('forgotten-or-no-password') }}
            </b-btn>
          </b-form>
        </b-col>
      </b-row>
    </template>
    <p v-else class="lead">{{ $t('login.success') }}</p>
  </div>
</template>

<script>
import {
  LOGIN_WITH_PASSWORD,
  GENERATE_ACCESS_TOKEN,
} from '@gid/vue-common/store/auth.module/types';

export default {
  data() {
    return {
      email: null,
      password: null,
      state: 'login',
      error: null,
    };
  },
  methods: {
    async login() {
      const path = this.$router.resolve({ name: 'login' }).href;
      const absoluteUrl = new URL(path, window.location.href).href;
      try {
        await this.$store.dispatch(LOGIN_WITH_PASSWORD, {
          email: this.email,
          password: this.password,
          role: 'brand',
          link: absoluteUrl,
        });

        this.afterLogin();
      } catch (error) {
        this.state = 'error';
        const err = { details: error };

        err.message = this.$t('_errors.general.title');
        if (err.details.response && err.details.response.status === 401) {
          if (
            err.details.response.data &&
            err.details.response.data.status === 'no_password'
          ) {
            err.message = this.$t('_errors.no_password.details');
          } else {
            err.message = this.$t('_errors.user_or_password.details');
          }
        } else if (
          err.details.response &&
          err.details.response.status === 422
        ) {
          err.message = this.$t('_errors.user_or_password.details');
        } else if (err.details.request) {
          err.message = this.$t('_errors.network.details');
        } else {
          err.message = this.$t('_errors.general.title');
        }
        this.error = err;
      }
    },
    afterLogin() {
      if (this.$store.state.auth.user) {
        this.redirect(this.$store.state.auth.user.profiles);
      } else {
        // Wait until user info is available to descide
        // where to redirect to, based on contact profiles
        const unwatch = this.$store.watch(
          (state) => state.auth.user,
          (user) => {
            if (user) {
              this.redirect(user.profiles);
              unwatch();
            }
          },
        );
      }
    },
    redirect(contactProfiles) {
      if (
        (contactProfiles.length === 1 &&
          ['accounting', 'reporting'].includes(contactProfiles[0])) ||
        contactProfiles.includes('admin')
      ) {
        this.$router.push({ name: 'quote-approval-jobs' });
      } else if (
        contactProfiles.length === 1 &&
        contactProfiles[0] === 'approval'
      ) {
        this.$router.push({ name: 'quote-approval-jobs' });
      } else {
        this.$router.push({ name: 'quote-approval-jobs' });
      }
    },
  },
  created() {
    if (this.$route.query.token) {
      this.$store
        .dispatch(GENERATE_ACCESS_TOKEN, {
          refresh_token: this.$route.query.token,
        })
        .then(() => {
          this.afterLogin();
        })
        .catch((error) => {
          this.state = 'error';
          const err = { details: error };
          if (error.response) {
            err.message = error.response.data.msg || error.response.data;
          } else if (error.request) {
            err.message = this.$t('_errors.network.details');
          } else {
            err.message = error.message;
          }
          this.error = err;
        });
    }
  },
};
</script>
